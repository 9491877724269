import axios from '@/utilities/axios'
import { getUser } from '@/utilities/accessControl'

export default {
  namespaced: true,
  state: {
    account_id: '',
    contract_id: '',
    unity_id: '',
    company_id: '',
    listaAccounts: [],
    listaContratos: [],
    listaUnidades: [],
    listaEmpresas: [],
    filteredUnities: [],
    filteredCompanies: []
  },
  mutations: {
    changeFilter(state, data) {
      switch (data.tipo) {
        case 'account':
          state.account_id = data.value
          break
        case 'contract':
          state.contract_id = data.value
          break
        case 'unity':
          state.unity_id = data.value
          break
        case 'company':
          state.company_id = data.value
          break
      }
    },
    LISTAGEM_ACCOUNTS(state, data) {
      state.listaAccounts = data.map((account) => ({ value: account.id, name: account.name }))
    },
    LISTAGEM_CONTRATOS(state, data) {
      state.listaContratos = data.map((contrato) => ({ value: contrato.id, name: contrato.name }))
    },
    LISTAGEM_UNIDADES(state, data) {
      state.listaUnidades = data.map((unidade) => ({
        value: unidade.id,
        name: unidade.name
      }))
    },
    LISTAGEM_EMPRESAS(state, data) {
      state.listaEmpresas = data.map((empresa) => ({
        value: empresa.id,
        name: empresa.name
      }))
    },
    setFilteredUnities(state, unities) {
      state.filteredUnities = unities
    },
    setFilteredCompanies(state, companies) {
      state.filteredCompanies = companies
    },
    syncState(state, rootState) {
      if (rootState?.filter) {
        state.account_id = rootState.filter.account_id || ''
        state.contract_id = rootState.filter.contract_id || ''
        state.unity_id = rootState.filter.unity_id || ''
        state.company_id = rootState.filter.company_id || ''
        state.listaAccounts = rootState.filter.listaAccounts || []
        state.listaContratos = rootState.filter.listaContratos || []
        state.listaUnidades = rootState.filter.listaUnidades || []
        state.listaEmpresas = rootState.filter.listaEmpresas || []
        state.filteredUnities = rootState.filter.filteredUnities || []
        state.filteredCompanies = rootState.filter.filteredCompanies || []
      }
    }
  },
  actions: {
    initializeState({ commit, rootState }) {
      commit('syncState', rootState)
    },
    async LISTAR_ACCOUNTS({ commit, dispatch }) {
      try {
        const response = await axios.get(`/api/admin/user/${getUser.id}`)
        commit('LISTAGEM_ACCOUNTS', response.data.user.accounts)
        await dispatch('LISTAR_CONTRATOS')
      } catch (error) {
        console.error('Error fetching accounts:', error)
      }
    },
    async LISTAR_CONTRATOS({ commit, state, dispatch }) {
      try {
        const response = await axios.get('/api/admin/contrato', {
          headers: {
            'account-id': state.account_id || getUser.account_id[0].id
          }
        })
        commit('LISTAGEM_CONTRATOS', response.data.contracts)
        await dispatch('LISTAR_UNIDADES')
      } catch (error) {
        console.error('Error fetching contracts:', error)
      }
    },
    async LISTAR_UNIDADES({ commit, state, dispatch }) {
      try {
        const response = await axios.get('/api/admin/unidade', {
          headers: {
            'account-id': state.account_id || getUser.account_id[0].id
          },
          params: {
            contract_uuid: state.contract_id
          }
        })
        commit('LISTAGEM_UNIDADES', response.data.unidades)
        await dispatch('LISTAR_EMPRESAS')
      } catch (error) {
        console.error('Error fetching units:', error)
      }
    },
    async LISTAR_EMPRESAS({ commit, state }) {
      try {
        const response = await axios.get('/api/admin/empresa', {
          headers: {
            'account-id': state.account_id || getUser.account_id[0].id
          },
          params: {
            contract_uuid: state.contract_id,
            unit_uuid: state.unity_id
          }
        })
        commit('LISTAGEM_EMPRESAS', response.data.empresas)
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    }
  }
}
